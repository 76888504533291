import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Card({ post }) {
  // console.log("post:", post)
  const postImage = getImage(post.frontmatter.image)
  console.log(postImage)

  return (
    <Link to={"../" + post.fields.slug} className="card">
      <GatsbyImage
        image={postImage}
        alt={post.frontmatter.title + " cover image"}
      />
      <section>
        <h2>{post.frontmatter.title}</h2>
        <span>{post.frontmatter.date}</span>
        <p
          dangerouslySetInnerHTML={{
            __html: post.excerpt,
          }}
        />
      </section>
    </Link>
  )
}

import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Card from "../components/card"

const pageTitle = "Blog"

export default function BlogPage({ data }) {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout pageTitle={pageTitle}>
      <header className="page-header">
        <h1>{pageTitle}</h1>
      </header>
      <section className="container">
        <ol className="posts-grid">
          {posts.map((post) => {
            return (
              <li key={post.fields.slug} className="posts-grid-item">
                <Card post={post} />
              </li>
            )
          })}
        </ol>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        id
        excerpt
        frontmatter {
          date
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
